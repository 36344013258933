import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


import PageLayout from "examples/LayoutContainers/PageLayout";
import Card from "@mui/material/Card";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import MembershipList from "layouts/webpages/components/membership_list";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GymReview = () => {
  let query = useQuery();
  let gymId = query.get("gymId");
  const navigate = useHistory().push;
  const [gymName, setGymName] = useState('----');
  const [phone, setPhone] = useState('----');
  const [gymEmail, setGymEmail] = useState('---');
  const [gymLocation, setGymLocation] = useState('---');
  const [gymLogo, setGymLogo] = useState('');
  const [memberships, setMemberships] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    if(gymId){
        getGymInformation();
    }
    else{
        navigate('/forgedfitness');
    }
  }, [gymId]);

  const getGymInformation = async () => {
    try {
      const response = await fetch('https://www.phynux.tech/api/forged-fitness/getReviewGymInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ gym_id: gymId }),
      });
      const data = await response.json();

      if (!data.status) {
        navigate('/forgedfitness');
      } else {
        if(data.name != ''){
            setGymName(data.name);
            setGymEmail(data.email);
            setPhone(data.phone);
            setGymLocation(data.location);
            setGymLogo(data.picture);
            setMemberships(data.memberships);
            setPhotos(data.photos);
        }
        else{
           navigate('/forgedfitness');
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleImageClick = (imageUri) => {
    setSelectedImage(imageUri);
    setModalVisible(true);
  };

  const signupButton = () => {
      localStorage.setItem('membership', JSON.stringify(selectedMembership));
      navigate(`/signup?gymId=${gymId}&gymName=${gymName}`);
  };

  // Styles
  const styles = {
      container: {
        fontFamily: 'Helvetica, Arial, sans-serif', // A more neutral font choice for broad compatibility
        padding: '60px 20px',
        color: '#FFFFFF', // Bright white text for high contrast
        minHeight: '100vh',
        textAlign: 'center',
        maxWidth: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      gymInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
        color: 'white', // Text color
      },
      gymLogo: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        borderWidth: '5px',
        borderColor: '#7458e7',
      },
      membershipOption: {
        border: '2px solid #7458e7', // Match React Native border color and thickness
        padding: '10px',
        margin: '5px 0',
        cursor: 'pointer',
        backgroundColor: '#333', // Default background
        borderRadius: '8px', // Rounded corners
        color: 'white', // Text color
      },
      selectedMembership: {
        backgroundColor: '#355E3B', // Selected item background color
      },
      galleryPhoto: {
        width: '100px',
        height: '100px',
        margin: '5px',
        cursor: 'pointer',
        borderRadius: '10px', // Rounded corners
        borderWidth: '2px',
        borderColor: '#7458e7', // Border color
      },
      modal: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      modalContent: {
        width: '80%',
        maxHeight: '90%',
        borderRadius: '10px', // Added rounded corners for consistency
      },
      signupButton: {
        backgroundColor: '#8A9A5B', // Match React Native sign-up button color
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '30px', // More pronounced rounded corners
        cursor: 'pointer',
        margin: '20px 0', // Ensure some spacing at the bottom
      },
    };

  return (
    <PageLayout>
    <div style={styles.container}>
      <div style={styles.gymInfo}>
        <img src={`data:image/jpeg;base64,${gymLogo}`} alt="Gym Logo" style={styles.gymLogo} />
        <h1>{gymName}</h1>
      </div>

      <div>
        <MembershipList memberships={memberships} setSelectedMembership={(membership) => {setSelectedMembership(membership)}} selectedMembership={selectedMembership} />
      </div>

      <div>
        <h2>Gallery</h2>
        <div>
          {photos.map((photo, index) => (
            <img key={index} src={`data:image/jpeg;base64,${photo.base64_data}`} alt="Gym" style={styles.galleryPhoto} onClick={() => handleImageClick(photo.base64_data)} />
          ))}
        </div>

        {modalVisible && (
          <div style={styles.modal} onClick={() => setModalVisible(false)}>
            <img src={`data:image/jpeg;base64,${selectedImage}`} alt="Selected" style={styles.modalContent} />
          </div>
        )}
      </div>

      <div>
        <p>Phone: {phone}</p>
        <p>Email: {gymEmail}</p>
        <p>Location: {gymLocation}</p>
        {/*<p>Website: <a href="https://example.gym.com">https://example.gym.com</a></p>*/}
      </div>

      <button style={styles.signupButton} onClick={signupButton} disabled={!selectedMembership}>
        Sign Up!
      </button>
    </div>
    </PageLayout>
  );
};

export default GymReview;
