
// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Billing page components
import Bill from "layouts/webpages/components/membership_list/option";
import { Opacity } from "@mui/icons-material";

function BillingInformation({memberships, setSelectedMembership, selectedMembership}) {

  return (
    <Card id="delete-account">
      <VuiBox>
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Membership Options
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        {memberships.length > 0 ? (
          <div style={{overflow:'auto', maxHeight: '750px'}}>
          {memberships.map((option, index) => (
            <VuiButton variant='text' onClick={() => {setSelectedMembership(option)}}>
             <Bill
              name={option.membership_name}
              description={option.description}
              price={option.cost}
              type={option.membership_type}
              isSelected={selectedMembership == option? true : false}
           />
           </VuiButton>
          ))}
          </div>
        ):(
          <p style={{color: 'white', padding: '10px'}}>No Memberhips Added</p>
        )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default BillingInformation;
