
// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Billing page components
import Bill from "layouts/gym/components/Bill";

function BillingInformation({memberships, removeMembership, editMembership}) {

  return (
    <Card id="delete-account">
      <VuiBox>
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Membership Options
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        {memberships.length > 0 ? (
          <div style={{overflow:'auto', maxHeight: '750px'}}>
          {memberships.map((option, index) => (
             <Bill
              name={option.name}
              description={option.description}
              price={option.price}
              type={option.paymentType}
              removeMembership={() => removeMembership(index)}
              editMembership={() => editMembership(index)}
           />
          ))}
          </div>
        ):(
          <p style={{color: 'white', padding: '10px'}}>No Memberhips Added</p>
        )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default BillingInformation;
