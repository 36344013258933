import React from 'react';
import { FaApple, FaGooglePlay } from 'react-icons/fa'; // Import icons from react-icons library
import AppIcon from 'assets/images/forgedfitnessLogo.png'


import PageLayout from "examples/LayoutContainers/PageLayout";
import Card from "@mui/material/Card";
import VuiButton from "components/VuiButton";

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  appDownloadPrompt: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '40px',
    borderRadius: '10px',
    maxWidth: '400px',
    margin: '0 auto',
    textAlign: 'center',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)', // Add a subtle shadow for depth
  },
  appInfo: {
    marginBottom: '40px', // Increase spacing between logo and app name
  },
  appLogo: {
    width: '150px', // Enlarge the logo for better visibility
    height: '150px',
    borderRadius: '50%',
    marginBottom: '20px', // Adjust margin for better alignment
  },
  downloadLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    display: 'block',
    marginBottom: '20px', // Increase spacing between download links
    padding: '10px 20px', // Add padding for better clickability
    backgroundColor: '#555', // Add background color to download links
    borderRadius: '5px', // Add rounded corners to download links
    color: '#fff',
    textDecoration: 'none',
    transition: 'background-color 0.3s ease', // Add smooth transition effect
  },
  linkHover: {
    backgroundColor: '#777', // Change background color on hover
  },
  icon: {
    marginRight: '10px',
  },
};

const AppDownloadPrompt = () => {

  return (
    <PageLayout>
    <div style={styles.container}>
        <Card style={{textAlign: 'center', color: 'white', width: '40%'}}>
          <div style={styles.appInfo}>
            <img src={AppIcon} alt="App Logo" style={styles.appLogo} />
            <h2 style={{ margin: 0 }}>Forged-Fitness</h2>
          </div>
          <div style={styles.downloadLinks}>
            <VuiButton variant='text'>
            <a
              href="https://apps.apple.com/us/app/forged-fitness/id6477125632"
              style={styles.link}
              onMouseEnter={(e) => e.target.style.backgroundColor = '#777'}
              onMouseLeave={(e) => e.target.style.backgroundColor = '#555'}
            >
              <FaApple style={styles.icon} /> <p style={{alignSelf: 'center'}}>Download on the App Store</p>
            </a>
            </VuiButton>
            <VuiButton variant='text'>
            <a
              href="https://play.google.com/store/apps/details?id=com.phynux.forgedfitness"
              style={styles.link}
              onMouseEnter={(e) => e.target.style.backgroundColor = '#777'}
              onMouseLeave={(e) => e.target.style.backgroundColor = '#555'}
            >
              <FaGooglePlay style={styles.icon} /> <p style={{alignSelf: 'center'}}>Get it on Google Play</p>
            </a>
            </VuiButton>
          </div>
        </Card>
    </div>
    </PageLayout>
  );
}

export default AppDownloadPrompt;
