
import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Vision UI Dashboard React themes
import theme from "assets/theme";

// RTL plugins
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import PhynuxWebPage from './layouts/webpages/forgedFitnessHome'
import Stripe_Connect from './layouts/webpages/stripe_connect'
import Gym_checkout from './layouts/webpages/checkout'
import membershipOptions from './layouts/webpages/membershipOptions'
import gymSetup from './layouts/webpages/gym_setup'
import gymSignup from './layouts/webpages/gym_signup_form'
import gymReview from './layouts/webpages/gymReview'
import appDownload from './layouts/webpages/app_screen'
import viewMember from './layouts/webpages/view_member'
import Admin from './layouts/company_dashboard'
import SignIn from './layouts/authentication/sign-in'
import SignUp from './layouts/authentication/sign-up'

// Vision UI Dashboard React routes
import routes from "routes";

// Vision UI Dashboard React contexts
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <VuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="info"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="white"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </VuiBox>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand=""
            brandName="Forged-Fitness"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Switch>
        {getRoutes(routes)}
        <Route exact path='/' component={PhynuxWebPage} key={'home'} />
        <Route exact path='/connect_stripe' component={Stripe_Connect} key={'Sc'} />
        <Route exact path='/checkout_stripe' component={Gym_checkout} key={'gym_checkout'} />
        <Route exact path='/memberships' component={membershipOptions} key={'membershipOptions'} />
        <Route exact path='/Signup' component={gymSignup} key={'gym_signup'} />
        <Route exact path='/forgedfitness/gymReview' component={gymReview} key={'gym_Review'} />
        <Route exact path='/forgedfitness/view_member' component={viewMember} key={'view_member'} />
        <Route exact path='/forgedfitness/download_app' component={appDownload} key={'appDownload'} />
        <Route exact path='/forgedfitness/api/admin' component={Admin} key={'admin'} />
        <Route exact path='/authentication/sign-in' component={SignIn} key={'sign_in'} />
        <Route exact path='/authentication/sign-up' component={SignUp} key={'sign_up'} />
      </Switch>
    </ThemeProvider>
  );
}
