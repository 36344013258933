
import React, { useState, useEffect } from "react";

import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiBadge from "components/VuiBadge";
import avatar1 from "assets/images/forgedfitnessLogo.png";
import VuiInput from "components/VuiInput";
import { useHistory } from 'react-router-dom';

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";


function Author({ image, name, email }) {
  return (
    <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <VuiBox mr={2}>
        <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}



function Function({ job, org }) {
  return (
    <VuiBox display="flex" flexDirection="column">
      <VuiTypography variant="caption" fontWeight="medium" color="white">
        {job}
      </VuiTypography>
      <VuiTypography variant="caption" color="text">
        {org}
      </VuiTypography>
    </VuiBox>
  );
}


function Tables() {
  const [searchResults, setSearchResults] = useState([]);
  const [search_input, setSearch_input] = useState('');
  const navigate = useHistory().push;

  const data = {
    columns: [
      { name: "name", align: "left" },
      { name: "last checkin", align: "center" },
      { name: "action", align: "center" },
    ],
    rows: [
    ],
  };

  const appendRows = (rows, newData) => {
    newData.forEach((item) => {
      console.log(item)
      const newRow = {
        name: <Author image={avatar1} name={item.name} email={item.email} />,
        function: <Function job={item.job} org={item.org} />,
        status: (
          <VuiBadge
            variant="standard"
            badgeContent={item.status === 'Online' ? 'Online' : 'Offline'}
            color={item.status === 'Online' ? 'success' : 'error'}
            size="xs"
            container
            sx={({ palette: { white, success, error }, borders: { borderRadius, borderWidth } }) => ({
              background: item.status === 'Online' ? success.main : error.main,
              border: `${borderWidth[1]} solid ${item.status === 'Online' ? success.main : error.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ),
        employed: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {item.employed}
          </VuiTypography>
        ),
        action: (
          <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick={() => navigate(`/forgedfitness/view_member?email=${item.email}`)}>
            Edit
          </VuiTypography>
        ),
      };
      setSearchResults(prevState => {
        const newState = [...prevState];
        newState.push(newRow);
        return newState;
      })
    });
  };

  const getUserToken = () => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'userToken') {
            return value;
        }
    }
    return null;
  };


  const fetchData = async () => {
    const Has_token = getUserToken();
    if (Has_token) {
      try {
        const searchEndpoint = 'https://www.phynux.tech/api/forged-fitness/searchUsers';
        const response = await fetch(searchEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_input: search_input, token: Has_token }),
        });
        const data1 = await response.json();
        setSearchResults([]);
        appendRows(data.rows, data1.users);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white" mr='10px'>
                Members
              </VuiTypography>
              <VuiInput type="search" placeholder="Search Members..." value={search_input} onChange={e => {setSearch_input(e.target.value); fetchData();}} fontWeight="500" />
            </VuiBox>
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              <Table columns={data.columns} rows={searchResults} />
            </VuiBox>
          </Card>
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Tables;
