import React, { useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import PageLayout from "examples/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import BillingInformation from "layouts/gym/components/BillingInformation";
import Icon from "@mui/material/Icon";

Modal.setAppElement('#root');

const GymSetupScreen = () => {
  const [gymName, setGymName] = useState('');
  const [logo, setLogo] = useState(null);
  const [membershipOptions, setMembershipOptions] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const navigate = useHistory();
  const [newMembershipOption, setNewMembershipOption] = useState({
    name: '',
    description: '',
    price: '',
    paymentType: 'month',
  });
  const [locationText, setLocationText] = useState('');
  const [phoneText, setPhoneText] = useState('');
  const [currentEditIndex, setCurrentEditIndex] = useState(-1);
  const [photos, setPhotos] = useState([]);
  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const getUserToken = () => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'userToken') {
            return value;
        }
    }
    return null; // Return null if userToken cookie is not found
  };


  const token = getUserToken();

  const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        // Convert to the format you described, if necessary
        const blob = new Blob([file], { type: 'image/jpeg' }); // Assuming you want to force JPEG
        const logoWithDetails = new File([blob], "logo.jpg", { type: 'image/jpeg' });
        setLogo(logoWithDetails);
      }
    };

  const addMembershipOption = () => {
    setModalVisible(true);
  };

  const handleAddMembershipOption = async () => {
    if (newMembershipOption.name && newMembershipOption.description && newMembershipOption.price) {
      const updatedOptions = [...membershipOptions];
      if (currentEditIndex !== -1) {
        updatedOptions[currentEditIndex] = newMembershipOption;
      } else{
        setMembershipOptions([...updatedOptions, newMembershipOption]);
        setModalVisible(false);
        setNewMembershipOption({
          name: '',
          description: '',
          price: '',
          paymentType: 'month',
        });
      }
      setCurrentEditIndex(-1);
    } else {
      alert('Please fill out all fields before adding a membership option.');
    }
  };


  const handleEditMembership = (index) => {
    const edit_membership = membershipOptions[index];
    setNewMembershipOption({
      name: edit_membership.name,
      description: edit_membership.description,
      price: edit_membership.price,
      paymentType: edit_membership.paymentType,
    });
    setModalVisible(true);
  };

  const handleRemoveMembershipOption = (index) => {
    const updatedOptions = [...membershipOptions];
    updatedOptions.splice(index, 1);
    setMembershipOptions(updatedOptions);
  };

  const handleRemovePhoto = (index) => {
      setPhotos((currentPhotos) => currentPhotos.filter((_, i) => i !== index));
    };

    const handlePhotosChange = async (event) => {
      const selectedFiles = Array.from(event.target.files);
      const newPhotos = [];

      for (let file of selectedFiles.slice(0, 8 - photos.length)) {
        const photo = await convertToJPEG(file);
        newPhotos.push(photo);
      }

      setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
    };

    const convertToJPEG = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imgElement = document.createElement("img");
          imgElement.src = e.target.result;
          imgElement.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = imgElement.width;
            canvas.height = imgElement.height;
            ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
              resolve(new File([blob], file.name, { type: "image/jpeg" }));
            }, "image/jpeg");
          };
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    const handleChange = (event) => {
        let inputVal = event.target.value.replace(/[^0-9]/g, '');
        if (inputVal.length > 2) {
          // Insert a decimal two places from the end
          inputVal = inputVal.slice(0, -2) + '.' + inputVal.slice(-2);
        } else if (inputVal.length === 2) {
          inputVal = '0.' + inputVal;
        } else if (inputVal.length === 1) {
          inputVal = '0.0' + inputVal;
        } else {
          inputVal = '0.00';
        }

        setNewMembershipOption({ ...newMembershipOption, price: '200' })
        console.log(newMembershipOption.price)
      };


  const handleSubmit = async () => {
      if (!gymName || !logo || membershipOptions.length === 0 || !locationText || !phoneText) {
        alert('Please fill out all required fields before submitting.');
        return;
      }

      const formData = new FormData();
      formData.append('gym_name', gymName);
      // Directly append the File object to formData.
      // 'logo' needs to be a File object.
      formData.append('logo', logo);
      formData.append('location', locationText);
      formData.append('phone', phoneText);
      formData.append('memberships', JSON.stringify(membershipOptions));
      formData.append('token', token);
      photos.forEach((photo, index) => formData.append(`photos[${index}]`, photo));

      try {
        const response = await fetch('https://www.phynux.tech/api/forged-fitness/gym_upload', {
          method: 'POST',
          body: formData,
          // Omitting 'Content-Type': 'multipart/form-data' header,
          // as the browser will set it automatically with the correct boundary.
        });

        const data = await response.json();

        if (data.status === 'success') {
          alert('Gym setup successful');
          navigate(`/forgedfitness/dashboard/${token}`);
        } else {
          alert('There was an error during the gym setup. Please try again.');
        }
      } catch (error) {
        console.error('Error submitting gym setup:', error);
        alert('Failed to submit gym setup. Please try again.');
      }
    };

  return (
    <PageLayout>
    <div style={styles.container}>
      <Card>
      <h1 style={styles.title}>Gym Setup</h1>
      <Card style={{alignSelf: 'center'}}>
        <h3 style={{color: 'white', marginBottom: '10px'}}>Gym Logo</h3>
          {logo ? (
            <img src={URL.createObjectURL(logo)} alt="Gym Logo" style={{
              width: '80px',
              height: 'auto',
              borderRadius: '5px',
              alignSelf: 'center'
            }}/>
            ) : (
              <div style={{color: 'white', margin: '10px', alignSelf: 'center'}}>Upload Logo</div>
            )}
            <input type="file" onChange={handleFileChange} accept="image/*" style={{
              color: 'white',
              margin: '10px',
              alignSelf: 'center'
            }} />
        </Card>

        <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Gym Name</p>
        <VuiInput type="text" placeholder="Enter Gym Name"  onChange={(e) => setGymName(e.target.value)} fontWeight="500" style={{margin: "5px"}} />
        <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Location</p>
        <VuiInput type="text" placeholder="Enter Location"  onChange={(e) => setLocationText(e.target.value)} fontWeight="500" style={{margin: "5px"}} />
        <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Contact Number</p>
        <VuiInput type="tel" placeholder="Enter Phone"  onChange={(e) => setPhoneText(e.target.value)} fontWeight="500" style={{margin: "5px"}} />


      <div style={styles.membershipOptionsList}>
      <BillingInformation memberships={membershipOptions} removeMembership={handleRemoveMembershipOption} editMembership={handleEditMembership}/>
        <button onClick={addMembershipOption} style={styles.addButton}>Add Membership Option</button>
      </div>

      <div style={styles.photosContainer}>
          {photos.map((photo, index) => (
            <div key={index} style={styles.photoPreview}>
              <img
                src={URL.createObjectURL(photo)}
                alt={`Photo ${index + 1}`}
                style={styles.photoImage}
              />
              <VuiButton variant='text' color="error" style={styles.removePhotoButton} onClick={() => handleRemovePhoto(index)}>
                <Icon sx={{ mr: "4px" }}>delete</Icon>&nbsp;DELETE
              </VuiButton>
            </div>
          ))}
          <input
            id="photoInput"
            type="file"
            multiple
            accept="image/*"
            onChange={handlePhotosChange}
            style={{ display: 'none' }}
          />
        </div>
        {photos.length < 8 && (
          <VuiButton variant='text' onClick={() => document.getElementById('photoInput').click()} ><p style={styles.addPhotoButton} >Add Photo</p></VuiButton>
        )}

      <button onClick={handleSubmit} style={styles.submitButton}>Submit</button>
      </Card>
      <Modal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisible(false)}
        style={styles.modal}>
        <Card>
        <h2 style={{color: 'white'}}>Add New Membership Option</h2>
        <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Membership Name</p>
        <VuiInput type="text" placeholder="Enter Membership Name" value={newMembershipOption.name}  onChange={(e) => setNewMembershipOption({ ...newMembershipOption, name: e.target.value })} fontWeight="500" style={{margin: "5px"}} />
        <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Description</p>
        <VuiInput type="text" placeholder="Enter Description" value={newMembershipOption.description}  onChange={(e) => setNewMembershipOption({ ...newMembershipOption, description: e.target.value })} fontWeight="500" style={{margin: "5px"}} />
        <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Membership Price (Tax Included)</p>
        <VuiInput type="text" placeholder="Enter Description" value={newMembershipOption.price}  onChange={(e) => {
            const input = e.target.value;
              if (/^\d*\.?\d*$/.test(input) || input === "") {
                setNewMembershipOption({ ...newMembershipOption, price: String(input) });
              }
          }} fontWeight="500" style={{margin: "5px"}} />

        <select
          value={newMembershipOption.paymentType}
          onChange={(e) => setNewMembershipOption({ ...newMembershipOption, paymentType: e.target.value })}
          style={styles.select}>
          <option value="month">Monthly</option>
          <option value="year">Yearly</option>
          <option value="day">Day Pass</option>
        </select>
        <div style={styles.modalButtons}>
          <VuiButton variant='text' onClick={handleAddMembershipOption}><p style={styles.modalButton}>Add</p></VuiButton>
          <VuiButton variant='text' onClick={() => setModalVisible(false)}><p style={styles.modalButton}>Cancel</p></VuiButton>
        </div>
        </Card>
      </Modal>
    </div>
    </PageLayout>
  );
};

// Styles similar to React Native's StyleSheet
const styles = {
  container: {
    padding: 20,
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
    minHeight: '100vh',
  },
  title: {
    marginBottom: 20,
    textAlign: 'center',
    color: 'white',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
  },
  logo: {
    width: 100,
    height: 100,
    marginBottom: 10,
    borderRadius: '50%',
  },
  logoPlaceholder: {
    width: 100,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    borderRadius: '50%',
    marginBottom: 10,
  },
  fileInput: {
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
    border: '1px solid #ccc',
  },
  membershipOptionsList: {
    marginBottom: 20,
  },
  membershipOption: {
    backgroundColor: '#eee',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  removeButton: {
    backgroundColor: '#ff4d4d',
    color: 'white',
    padding: 5,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
  },
  addButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
  },
  submitButton: {
    width: '100%',
    backgroundColor: '#008CBA',
    color: 'white',
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
  },
  modal: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
      padding: '20px',
      borderRadius: '10px',
      backgroundColor: 'transparent',
      
    },
  },
  select: {
    width: '100%',
    padding: 10,
    marginVertical: '20px',
    borderRadius: 5,
    border: '1px solid #ccc',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalButton: {
    backgroundColor: '#008CBA',
    color: 'white',
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
    marginTop: 10,
  },
  photosContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  photoPreview: {
    width: '100px',
    height: '100px',
    position: 'relative',
  },
  photoImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px'
  },
  removePhotoButton: {
    position: 'absolute',
    bottom: '5px',
    right: '5px',
    // Additional styles for the remove button
  },
  addPhotoButton: {
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'center'
  },
};

export default GymSetupScreen;
