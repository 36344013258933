
// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Billing page components
import Bill from "layouts/store/components/item";

function BillingInformation({store_items, removeProduct, editMembership}) {

  return (
    <Card id="delete-account">
      <VuiBox>
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Products
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        {store_items.length > 0 ? (
          <div style={{overflow:'auto', maxHeight: '750px'}}>
          {store_items.map((option, index) => (
             <Bill
              name={option.item_name}
              description={option.item_description}
              price={Number(Math.floor(option.item_price)/100)}
              image={option.image_base64}
              removeMembership={() => removeProduct(index)}
              editMembership={() => editMembership(index)}
              isSaved={option.isSaved}
           />
          ))}
          </div>
        ):(
          <p style={{color: 'white', padding: '10px'}}>No Items Added</p>
        )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default BillingInformation;
