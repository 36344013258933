import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Container, Grid, Box, Typography, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoginPage from './components/LoginPage';
import Table from "examples/Tables/Table";
import PageLayout from "examples/LayoutContainers/PageLayout";
import './App.css';
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiBadge from "components/VuiBadge";
import avatar1 from "assets/images/forgedfitnessLogo.png";
import VuiInput from "components/VuiInput";
import { useHistory } from 'react-router-dom';;

function Author({ image, name, email }) {
  return (
    <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <VuiBox mr={2}>
        <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}


function convertUnixTimestampToDate(unixTimestamp) {
  // Create a new JavaScript Date object based on the Unix timestamp
  const date = new Date(unixTimestamp * 1000);

  // Options for formatting the date
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  // Format the date using the options
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
}





function Function({ job, org }) {
  return (
    <VuiBox display="flex" flexDirection="column">
      <VuiTypography variant="caption" fontWeight="medium" color="white">
        {job}
      </VuiTypography>
      <VuiTypography variant="caption" color="text">
        {org}
      </VuiTypography>
    </VuiBox>
  );
}

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [search_input, setSearch_input] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const data = {
    columns: [
      { name: "order number", align: "left" },
      { name: "ordered by", align: "center" },
      { name: "order date", align: "center" },
      { name: "status", align: "center" },
      { name: "action", align: "center" },
    ],
    rows: [],
  };

  const appendRows = (rows, newData) => {
    newData.forEach((item) => {
      const newRow = {
        ["order number"]: <Author image={avatar1} name={item.order_number} email={""} />,
        ["order date"]: <Function job={convertUnixTimestampToDate(item.order_date)} org={`Complete by: ${convertUnixTimestampToDate(Number(item.order_date)+259200)}`} />,
        ["ordered by"]: <Function job={item.email} org={item.account_id} />,
        status: (
          <VuiBadge
            badgeContent={item.status === 'Complete' ? 'Complete' : 'Incomplete'}
            color={item.status === 'Complete' ? 'success' : 'error'}
          />
        ),
        employed: (
          <Typography variant="caption" fontWeight="medium">
            {item.employed}
          </Typography>
        ),
        action: (
          <Typography
            component="a"
            href="#"
            variant="caption"
            fontWeight="medium"
            onClick={() => {
              setSelectedUser(item);
              setSidebarOpen(true);
            }}
          >
            View
          </Typography>
        ),
      };
      setSearchResults((prevState) => {
        const newState = [...prevState];
        newState.push(newRow);
        return newState;
      });
    });
  };

  const getUserToken = () => {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'userToken') {
        return value;
      }
    }
    return null;
  };

  const fetchData = async () => {
    const Has_token = getUserToken();
    if (Has_token) {
      try {
        const searchEndpoint = 'https://www.phynux.tech/api/forged-fitness/searchOrders';
        const response = await fetch(searchEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ search_input }),
        });
        const data1 = await response.json();
        setSearchResults([]);
        appendRows(data.rows, data1.orders);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };


  const UserDetailsComponent = ({ selectedUser }) => {
    return (
      <>
        {selectedUser.hardware && (
          selectedUser.hardware.map((item, index) => (
            <Card key={index} style={{ color: 'white', marginBottom: 10 }}>
              <Typography variant="body1">{item.name}</Typography>
              <Typography variant="body2">{item.sn}</Typography>
              <Typography variant="body2">Status: {item.status}</Typography>
            </Card>
          ))
        )}
      </>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  return (
    <>
    <PageLayout>
      <CssBaseline />
      <Box display="flex" flexDirection="column" alignItems="left">
        {!isLoggedIn ? (
          <LoginPage onLogin={handleLogin} />
        ) : (
          <div style={{ margin: '20px' }}>
            <Card>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                <Typography variant="h6">
                  Orders
                </Typography>
                <VuiInput type="search" placeholder="Search order..." value={search_input} onChange={e => {setSearch_input(e.target.value); fetchData();}} fontWeight="500" style={{marginLeft: 10}} />
              </Box>
              <Box>
                <Table columns={data.columns} rows={searchResults} />
              </Box>
            </Card>
          </div>
        )}
      </Box>
      <Drawer
        anchor="right"
        open={sidebarOpen}
        onClose={handleSidebarClose}
        sx={{ '& .MuiDrawer-paper': { width: '600px', backgroundColor: '#1E1E2F' } }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Typography variant="h6">
            Order Details
          </Typography>
          <IconButton onClick={handleSidebarClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={2}>
          <UserDetailsComponent selectedUser={selectedUser} />
        </Box>
      </Drawer>
      </PageLayout>
    </>
  );
};

export default App;
