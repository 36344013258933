
import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { createRoot } from 'react-dom/client';


// Vision UI Dashboard React Context Provider
import { VisionUIControllerProvider } from "context";
const container = document.getElementById('root');
const root = createRoot(container);


root.render(<BrowserRouter>
  <VisionUIControllerProvider>
    <App />
  </VisionUIControllerProvider>
</BrowserRouter>);

