import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const ViewMember = () => {
  const { username, token } = useParams();
  let query = useQuery();
  let member_email = query.get("email");
  let member_name = query.get("name");
  const [membershipName, setMembershipName] = useState('');
  const [gymName, setGymName] = useState('');
  const [membershipType, setMembershipType] = useState('');
  const [membershipCost, setMembershipCost] = useState('');
  const [membershipDescription, setMembershipDescription] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const navigate = useHistory().push;
  const [BanmodalIsOpen, setBanModalIsOpen] = useState(false);
  const [updatemodalIsOpen, setUpdateModalIsOpen] = useState(false);

  useEffect(() => {
    getMemberInformation();
  }, []);

  const getUserToken = () => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'userToken') {
            return value;
        }
    }
    return null; // Return null if userToken cookie is not found
  };

  const getMemberInformation = async () => {
    try {
      const response = await fetch('https://www.phynux.tech/api/forged-fitness/getMemberInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: member_email, token: getUserToken() })
      });
      const data = await response.json();
      console.log('Response:', data);

      setMembershipName(data.membership_name[0][0]);
      setMembershipType(data.membership_type[0][0]);
      setMembershipCost(data.cost/100);
      setMembershipDescription(data.membership_description);
      setGymName(data.gym_name);
      setUserEmail(data.email);
      setUserPhone(data.phone);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar/>
    <div style={styles.container}>
      <div style={styles.content}>
        <h2 style={styles.title}>{member_name}</h2>
        <Card style={{color:'white', margin: '10px'}}>
          <h3 style={styles.sectionTitle}>Membership Details</h3>
            <p><strong>Name:</strong> {membershipName}</p>
            <p><strong>Type:</strong> {membershipType}</p>
            <p><strong>Cost:</strong> ${membershipCost}</p>
            <p><strong>Description:</strong> {membershipDescription}</p>
          <button style={styles.actionButton} onClick={() => setBanModalIsOpen(true)}>Ban Member</button>
          {membershipType != "day" && (
            <button style={styles.updateButton} onClick={() => setUpdateModalIsOpen(true)}>Update Subscription Price</button>
          )}
        </Card>
        <Card style={{color:'white', margin: '10px'}}>
          <h3 style={styles.sectionTitle}>Member Information</h3>
          <p><strong>Email:</strong> {userEmail}</p>
          <p><strong>Phone:</strong> {userPhone}</p>
        </Card>
      </div>
      <Modal
          isOpen={BanmodalIsOpen}
          onRequestClose={() => setBanModalIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)', // Dim the background
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#333', // Match your theme
              color: 'white',
              borderRadius: '10px',
              padding: '20px',
              width: '80%', // Responsive width
              maxWidth: '600px', // Max width for larger screens
            },
          }}
          contentLabel="Subscription Payment"
        >
          <BanForm closeModal={() => setBanModalIsOpen(false)} token={'token'} user_email={userEmail} isDay={membershipType} navigate={navigate} />
        </Modal>
        <Modal
          isOpen={updatemodalIsOpen}
          onRequestClose={() => setBanModalIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)', // Dim the background
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#333', // Match your theme
              color: 'white',
              borderRadius: '10px',
              padding: '20px',
              width: '80%', // Responsive width
              maxWidth: '600px', // Max width for larger screens
            },
          }}
          contentLabel="Subscription Payment"
        >
          <UpdateForm closeModal={() => setUpdateModalIsOpen(false)} token={token} user_email={userEmail} membership_name={membershipName} membership_type={membershipType} />
        </Modal>
    </div>
  </DashboardLayout>
  );
};


const BanForm = ({ closeModal, token, user_email, isDay, navigate }) => {


    const revoke_membership = async (member_token) => {
    try {
      const response = await fetch('https://www.phynux.tech/api/forged-fitness/cancelMembership', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: member_token, isDay: [[isDay]] }) // Adjusted to include token
      });
      const data = await response.json();
      console.log('Response:', data);
      if(data.status == 'success'){
        navigate('/forgedfitness/dashboard')
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

    const BanMember = async () => {
    try {
      const response = await fetch('https://www.phynux.tech/api/forged-fitness/toggleBanMember', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login_token: token, user_email }) // Adjusted to include token
      });
      const data = await response.json();
      console.log('Response:', data);
      revoke_membership(data.user_loginToken)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div style={{ backgroundColor: "#424770", padding: "20px", borderRadius: "8px" }}>
      <h2>Are you sure you want to ban this member?</h2>
      <p><strong>Enacting this procedure will terminate the member's subscription and subsequently prohibit them from re-subscribing to your fitness establishment.</strong> It is important to note, however, that this restriction is not permanent. Should you decide to lift the ban at a later date, the individual in question will be required to complete a new registration process to rejoin.</p>
      <div styles={{'display': 'flex', 'flexDirection': 'row'}}>
        <button style={styles.actionButton} onClick={BanMember}>Ban Member</button>
         <button style={styles.cancelButton} onClick={() => closeModal()}>Cancel</button>
      </div>
    </div>
  );
};


const UpdateForm = ({ closeModal, token, user_email, membership_name, membership_type }) => {
  const [value, setValue] = useState(null);

  const handleChange = (event) => {
    let inputVal = event.target.value.replace(/[^0-9]/g, '');
    if (inputVal.length > 2) {
      // Insert a decimal two places from the end
      inputVal = inputVal.slice(0, -2) + '.' + inputVal.slice(-2);
    } else if (inputVal.length === 2) {
      inputVal = '0.' + inputVal;
    } else if (inputVal.length === 1) {
      inputVal = '0.0' + inputVal;
    } else {
      inputVal = '0.00';
    }

    setValue(inputVal);
    console.log(value*100)
    console.log(user_email)
  };


  const UpdatePrice = async () => {
    try {
      const response = await fetch('https://www.phynux.tech/api/forgedfitness/update-subscription-price', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token, user_email: user_email, membership_name: membership_name, membership_type: membership_type, new_price: value.value*100  }) // Adjusted to include token
      });
      const data = await response.json();
      console.log('Response:', data);
        if(data.message){
            closeModal()
        }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  return (
    <div style={{ backgroundColor: "#424770", padding: "20px", borderRadius: "8px" }}>
      <h2>Update Subscription</h2>
      <p>This procedure will update this members subscription price.</p>
      <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Subscription Price <strong style={{color: '#3e9c35'}}>(${value/100})</strong></p>
          <VuiInput type="text" placeholder="0.00" value={value} onChange={(e) => {
            const input = e.target.value;
            if (/^\d+$/.test(input) || input === "") {
              setValue(e.target.value)
            }}} />
      <div styles={{'display': 'flex', 'flexDirection': 'row'}}>
        <button style={styles.updateButton} onClick={UpdatePrice}>Update Price</button>
         <button style={styles.cancelButton} onClick={() => closeModal()}>Cancel</button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#FFF',
    padding: '20px',
  },
  backButton: {
    alignSelf: 'flex-start',
    marginBottom: '20px',
    padding: '10px 20px',
    backgroundColor: '#333',
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
  },
  content: {
    width: '100%',
    maxWidth: '600px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  membershipSection: {
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
  },
  sectionTitle: {
    marginBottom: '15px',
  },
  membershipInfo: {
    marginBottom: '20px',
  },
  actionButton: {
    width: '100%',
    backgroundColor: '#FF3B30',
    color: '#FFF',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginBottom: '10px',
  },
  cancelButton: {
    width: '100%',
    backgroundColor: '#333',
    color: '#FFF',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginBottom: '10px',
  },
  updateButton: {
    width: '100%',
    backgroundColor: 'blue',
    color: '#FFF',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginBottom: '10px',
  },
  memberInfo: {
    backgroundColor: '#333',
    padding: '20px',
    borderRadius: '10px',
  },
};

export default ViewMember;
