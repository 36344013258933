import React, { useState, useRef, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import { useHistory, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import PageLayout from "examples/LayoutContainers/PageLayout";
import Card from "@mui/material/Card";
import VuiInput from "components/VuiInput";

const stripePromise = loadStripe('pk_test_51OVFYTI07w8k740x3lAwqnyOaNOoBPzMuGfDaLefUjEdGHUGPeJdtk2oa7m1OmHIXsbR9LZnAzGn6Z7IjCSJB0nW00rOPuCq7E')

const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#fff",
      fontSize: "16px",
      backgroundColor: "#333", // Adjusted to a darker tone for elegance
      "::placeholder": {
        color: "#aab7c4",
      },

    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function SignupForm() {
  const hasInfo = 'false';
  let query = useQuery();
  const [accountCreated, setAccountCreated] = useState(false);
  const [LoginToken, setLoginToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [signature, setSignature] = useState(null);
  const signatureRef = useRef();
  const profileImageRef = useRef(null);
  const [gymLogo, setGymLogo] = useState('');
  const navigate = useHistory().push;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const selectedMembership = JSON.parse(localStorage.getItem('membership') || '{}');
  const gymId = query.get("gymId");
  const gymName = query.get("gymName");

  useEffect(() => {

    getGymInformation();
  }, []);

  const isPasswordValid = (password) => {
    return password.length >= 8;
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const revoke_membership = async (member_token) => {
    try {
      const response = await fetch('https://www.phynux.tech/api/forged-fitness/cancelMembership', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: member_token, isDay: [[selectedMembership.membership_type]] }) // Adjusted to include token
      });
      const data = await response.json();
      console.log('Response:', data);
      if(data.status == 'success'){
        navigate('/forgedfitness/dashboard')
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getGymInformation = async () => {
    try {
      const response = await fetch('https://www.phynux.tech/api/forged-fitness/getReviewGymInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ gym_id: gymId }),
      });
      const data = await response.json();

      if (!data.status) {
        navigate('/forgedfitness');
      } else {
        if (data.name !== '') {
          setGymLogo(data.picture);
        } else {
          navigate('/forgedfitness');
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsSubmitting(false)
    }
  };

  const formatDate = () => {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();
    return `${month}/${day}/${year}`;
  };

    const sendImageToBackend = async (token) => {
            const formData = new FormData();
            formData.append('gym_id', gymId);
            formData.append('membership_id', selectedMembership.membership_id);
            formData.append('isDay', selectedMembership.membership_type);

            if (hasInfo === 'false') {
                html2canvas(profileImageRef.current).then(canvas => {
                    canvas.toBlob(blob => {
                        formData.append('profileImage', blob, 'waiver.jpg');
                        formData.append('user_name', name);
                        formData.append('phone', phoneNumber);
                        formData.append('hasInfo', hasInfo);
                        formData.append('token', token);

                        fetch('https://www.phynux.tech/api/forged-fitness/gym_signup', {
                            method: 'POST',
                            body: formData,
                        })
                        .then(response => response.json())
                        .then(data => {
                            console.log(data.status);
                            if (data.status === 'success') {
                                alert('Success:Your subscription is confirmed!');
                                navigate('/forgedfitness/download_app');
                            } else {
                                alert('Error', 'There was an error signing up, please try again');
                            }
                        })
                        .catch(error => {
                            console.error('Error sending image to server:', error);
                        });
                    }, 'image/jpeg');
                });
            } else {
                formData.append('hasInfo', hasInfo);
                formData.append('token', token);

                fetch('https://www.phynux.tech/api/forged-fitness/gym_signup', {
                    method: 'POST',
                    body: formData,
                })
                .then(response => response.json())
                .then(data => {
                    console.log(data.status);
                    if (data.status === 'success') {
                        alert('Success: Your subscription is confirmed!');
                        navigate('/forgedfitness/download_app');
                    } else {
                        alert('Error', 'There was an error signing up, please try again');
                    }
                })
                .catch(error => {
                    console.error('Error sending image to server:', error);
                });
            }
    };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!signature || !stripe || !elements) {
      alert('Please provide a signature.');
      return;
    }
    if(password != confirmPassword){
      alert('Passwords mismatch');
      setPassword('');
      setConfirmPassword('');
      return;
    }
    setIsSubmitting(true)
    postRequest();
  };

  const handleClearSignature = () => {
    signatureRef.current.clear();
    setSignature(signatureRef.current.toDataURL());
  };


  const fetchClientSecret = async (token) => {
      const response = await fetch('https://www.phynux.tech/create-setup-intent', {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ gym_id: gymId }),
      });

      const data = await response.json();
      if (response.ok) {
        setClientSecret(data.clientSecret);
        setupSubPayment(token, data.clientSecret);
      } else {
        console.error('Failed to fetch client secret:', data);
      }
    };

    const setupSubPayment = async (token, cliSecret) => {
        const cardElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);
        const postalCode = document.getElementById('postalCode').value; // Assuming you give your input an ID

        const result = await stripe.confirmCardSetup(cliSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: name, // Example name, adjust accordingly
              address: {
                postal_code: postalCode, // Include the postal code in billing details
              },
            },
          },
        });

        if (result.error) {
          setIsSubmitting(false)
          console.error(result.error.message);
          alert('Subscription Error: ' + result.error.message);
        } else {
          console.log('PaymentMethod:', result.setupIntent.payment_method);
          await createSubscription(result.setupIntent.payment_method, token);
        }
    };

    const createSubscription = async (paymentMethodId, token) => {
        const response = await fetch('https://www.phynux.tech/subscribe', {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            gym_id: gymId,
            membership_cost: Math.round(Number(selectedMembership.cost) * 100),
            membership_name: selectedMembership.membership_name, // Ensure this matches your backend expectation
            type: selectedMembership.membership_type, // Ensure this matches your backend expectation
            paymentMethodId, // Sending the PaymentMethod ID to the backend
          }),
        });

        if (response.ok) {
          const data = await response.json();
          sendImageToBackend(token);
        } else {
          const errorData = await response.json();
          alert('Subscription Error: ' + errorData.error);
        }
      };

      const login = async () => {
        try {
          const response = await fetch('https://www.phynux.tech/api/forged-fitness/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }), // Ensure email and password are defined
          });
      
          const data = await response.json();
      
          if (data.status === 'success') {
            setLoginToken(data.login_token);
            console.log("SUCCESS")

            if(data.gym_id){
              const userChoice = window.confirm('This account is already linked to another gym. Would you like to cancel the existing membership and proceed with this new one?');
        
              if (userChoice) {
                revoke_membership(data.login_token)
                if (selectedMembership.membership_type === 'day') {
                  handlePaymentSheet(data.login_token);
                } else {
                  fetchClientSecret(data.login_token);
                }
              } else {
                console.log("no")
              }
            } else{
              if (selectedMembership.membership_type === 'day') {
                handlePaymentSheet(data.login_token);
              } else {
                fetchClientSecret(data.login_token);
              }
            }
            /*
            if (selectedMembership.membership_type === 'day') {
              handlePaymentSheet(data.login_token);
            } else {
              fetchClientSecret(data.login_token);
            }*/
          } else {
            setIsSubmitting(false);
            alert('Error: ' + data.status); // Consider improving error messaging here
          }
        } catch (error) {
          console.error('Error:', error);
          alert('An error occurred while creating the account. Please try again.');
          setIsSubmitting(false);
        }
      };
      
  const postRequest = async () => {

    if(!accountCreated){
        if (!isEmailValid(email)) {
      alert('Please enter a valid email address.');
      return;
    }
    if (!isPasswordValid(password)) {
      alert('Password must be at least 8 characters.');
      return;
    }

    console.log(email)
    console.log(password)

    fetch('https://www.phynux.tech/api/forged-fitness/create-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
            setAccountCreated(true)
            setLoginToken(data.login_token)
            if(selectedMembership.membership_type === 'day'){
                handlePaymentSheet(data.login_token);
            } else{
                fetchClientSecret(data.login_token);

            }
        } else {
          if(data.status == "Email already exists"){
            console.log("WELP")
            login()
          } else{
            setIsSubmitting(false)
            alert('Error: ' + data.status);
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    } else{
        if(selectedMembership.membership_type === 'day'){
            handlePaymentSheet(LoginToken);
        } else{
            fetchClientSecret(LoginToken);
        }
    }
  };

  const handlePaymentSheet = async (token) => {
      try {
        const response = await fetch('https://www.phynux.tech/payment-sheet', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            cost: Math.round(Number(selectedMembership.cost) * 100),
            gym_id: gymId,
            token: token
          }),
        });

        const data = await response.json();

        console.log(data);

        if (!response.ok) {
          setIsSubmitting(false)
          throw new Error('Failed to fetch payment sheet');
        }

        const stripe = await stripePromise;

        const cardElement = elements.getElement(CardNumberElement);
          const cardExpiryElement = elements.getElement(CardExpiryElement);
          const cardCvcElement = elements.getElement(CardCvcElement);
          const postalCode = document.getElementById('postalCode').value; // Assuming you give your input an ID

        const { error } = await stripe.confirmCardPayment(data.paymentIntent, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: name, // Example name, adjust accordingly
              address: {
                postal_code: postalCode, // Include the postal code in billing details
              },
            },
          },
        });

        if (error) {
          setErrorMessage('Payment failed. Please try again.');
          console.error('Error:', error);
          setIsSubmitting(false)

        } else {
          // Payment successful
          sendImageToBackend(token);
        }
      } catch (error) {
        setIsSubmitting(false)
        setErrorMessage('Error processing payment. Please try again.');
        console.error('Error:', error);
      }
    };


  const waiverText = `WAIVER OF LIABILITY
I, ${name}, acknowledge that I am voluntarily using the facilities and services provided by ${gymName}. I understand and agree that I am solely responsible for my health and well-being while using the facilities and services offered by ${gymName}.

I hereby release, waive, discharge, and covenant not to sue ${gymName}, its officers, employees, and agents from liability from any and all claims arising out of the use of the facilities and services.

I understand that the use of the facilities and services may involve risks, including but not limited to, personal injury, bodily harm, or death. I voluntarily assume all risks related to the use of the facilities and services and agree to indemnify ${gymName} against any and all liabilities arising from my use of the facilities and services.

By my signature I/We indicate that I/We have read and understand this Waiver of Liability. I am aware that this is a waiver and a release of liability and I voluntarily agree to its terms.`;

  return (
    <PageLayout>
    <div style={styles.container}>
      <Card style={{width:'80%'}}>
        <h2 style={styles.heading}>Sign Up</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <h3 style={styles.subHeading}>Account Information</h3>
            <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Email</p>
            <VuiInput type="email" placeholder="Email" value={email}  onChange={(e) => setEmail(e.target.value)} required style={{margin: '10px'}}/>
            <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Password</p>
            <VuiInput type="password" placeholder="Password" value={password}  onChange={(e) => setPassword(e.target.value)} required style={{margin: '10px'}}/>
            <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Confirm Password</p>
            <VuiInput type="password" placeholder="Confirm Password" value={confirmPassword}  onChange={(e) => setConfirmPassword(e.target.value)} required style={{margin: '10px'}}/>
          </div>

          <div style={styles.inputGroup}>
            <h3 style={styles.subHeading}>User Information</h3>
            <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Full Name</p>
            <VuiInput type="name" placeholder="Full Name" value={name}  onChange={(e) => setName(e.target.value)} required style={{margin: '10px'}}/>
            <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Phone Number</p>
            <VuiInput type="tel" placeholder="Phone Number" value={phoneNumber}  onChange={(e) => setPhoneNumber(e.target.value)} required style={{margin: '10px'}}/>
            <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Date of Birth</p>
            <VuiInput type="date" placeholder="Date of Birth" value={dateOfBirth}  onChange={(e) => setDateOfBirth(e.target.value)} required style={{margin: '10px'}}/>
          </div>
          <div style={styles.waiverGroup}>
            <div ref={profileImageRef} style={{backgroundColor: 'white', borderRadius: 5, display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center', width: '350px'}}>
                <div style={styles.headerGroup}>
                    <h2 style={{color: 'black', margin: '5px', fontSize: 20}}>{gymName}</h2>
                </div>
                <p style={styles.waiverText}>{waiverText}</p>
                <div style={styles.signatureGroup}>
                    <p style={styles.signatureText}>Date: {formatDate()}</p>
                    <p style={styles.signatureText}>Signature: </p>
                    <img src={signature} style={styles.signatureImage}/>
                </div>
            </div>
              <Card style={{width: '80%'}}>
              <div style={styles.signatureContainer}>
                <SignaturePad
                  ref={signatureRef}
                  canvasProps={{ className: 'signature-pad', style: styles.signaturePad }}
                  onEnd={(sig) => setSignature(signatureRef.current.toDataURL())}
                />
                <button type="button" onClick={handleClearSignature} style={styles.clearButton}>Clear Signature</button>
              </div>
            </Card>
          </div>

            <div style={{ backgroundColor: "#333", padding: "20px", borderRadius: "8px" }}>
                <h3 style={{ textAlign: "center", color: "#10B981" }}>Checkout</h3>
                <h4 style={{ color: "#fff" }}>{selectedMembership.membership_name}</h4>
                <p style={{ color: "#aab7c4" }}>
                  Total due now: <strong>${selectedMembership.cost}</strong>
                </p>
                <div style={{ marginBottom: "20px" }}>
                  <label style={{ marginBottom: "15px", display: "block", color: 'white' }}>
                    Card Number
                    <CardNumberElement options={ELEMENT_OPTIONS} required />
                  </label>
                  <label style={{ marginBottom: "15px", display: "block", color: 'white' }}>
                    Expiration Date
                    <CardExpiryElement options={ELEMENT_OPTIONS} />
                  </label>
                  <label style={{ marginBottom: "15px", display: "block", color: 'white' }}>
                    CVC
                    <CardCvcElement options={ELEMENT_OPTIONS} />
                  </label>
                  <label style={{ marginBottom: "15px", display: "block", color: 'white' }}>
                    ZIP/Postal Code
                    <input
                      id="postalCode" // Adding an ID for easier access
                      type="text"
                      inputMode="numeric" // Suggests to browsers to show a numeric keyboard
                      pattern="[0-9]*" // Allows only numbers
                      maxLength="5" // Adjust according to your requirements
                      required
                      placeholder="ZIP Code"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      style={{
                        fontSize: "16px",
                        color: "#fff",
                        backgroundColor: "#333",
                        padding: "10px 12px",
                        display: "block",
                        width: "90%",
                        '::placeholder': {
                          color: "#aab7c4"
                        }
                      }}
                    />
                  </label>
                </div>
            </div>

          <button type='submit' style={{padding: '15px 0',
                                width: '100%',
                                backgroundColor: isSubmitting ? "#9CA3AF" : "#10B981",
                                color: '#ffffff',
                                border: 'none',
                                borderRadius: '5px',
                                fontSize: '18px',
                                cursor: 'pointer',
                                marginTop: '20px',}}
                                disabled={isSubmitting}>{isSubmitting ? "Processing..." : `Checkout ${selectedMembership.cost}`}</button>
        </form>
      </Card>
    </div>
    </PageLayout>
  );
}

const styles = {
  container: {
    //backgroundColor: '#1e1e1e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  formContainer: {
    width: '80%',
    backgroundColor: '#272727',
    padding: '30px',
    borderRadius: '10px',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    color: 'white',
    marginBottom: '30px',
    textAlign: 'center',
  },
  subHeading: {
    color: '#ffffff',
    marginBottom: '20px',
    fontSize: '18px',
  },
  inputGroup: {
    marginBottom: '30px',
  },
  waiverGroup:{
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
    paddingHorizontal: '20px'
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#353535',
    color: '#ffffff',
    fontSize: '16px',
  },
  headerGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  gymLogo: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginLeft: '20px',
  },
  waiverContainer: {
    backgroundColor: '#353535',
    padding: '20px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  waiverText: {
    display: 'flex',
    color: 'black',
    fontSize: '12px',
    lineHeight: '1.6',
    marginRight: '15px',
    marginLeft: '15px',
    whiteSpace: 'pre-wrap'
  },
  signatureGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  signatureText: {
    color: 'black',
    fontSize: '14px',
    marginRight: '20px',
  },
  signatureImage: {
    width: '110px',
    height: '50px',
  },
  signatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  signaturePad: {
    width: '50%',
    height: '200px',
    border: '1px solid #ffffff',
    borderRadius: '5px',
    marginBottom: '20px',
    backgroundColor: 'white'
  },
  clearButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
  },
};


const PaymentForm = () => {
  return (
    <Elements stripe={stripePromise}>
      <SignupForm />
    </Elements>
  );
};

export default PaymentForm;
