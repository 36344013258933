

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useHistory } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";

// Vision UI Dashboard React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Vision UI Dashboard React context
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { SiStellar } from "react-icons/si";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState(null);
  const [load, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();


  const getInfo = async (token) => {
    const searchEndpoint = 'https://www.phynux.tech/api/forged-fitness/getGymInfo';

    try {
      const response = await fetch(searchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      setLogo(data.logo);
      setName(data.gym_name)
      setNotifications(data.notifications)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };

  const removeNotification = async (token, id) => {
    const searchEndpoint = 'https://www.phynux.tech/api/forged-fitness/removeNotification';

    try {
      const response = await fetch(searchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, id }),
      });
      const data = await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const validateToken = async (login_token) => {
      try {
        // Example endpoint - replace with your actual endpoint
        const endpoint = 'https://www.phynux.tech/api/forged-fitness/gym-protected';
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ login_token }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Server response:', data);

        // Example response handling
        if (data.status === 'Token validated') {
          if(data.membershipValid === 'yes') {
            if(data.stripe === 'yes') {
              if(data.isSetup === 'yes') {
                getInfo(login_token);
              } else{
                alert("please setup your gym")
                history.push('/gym')
              }
            } else{
              history.push('/connect_stripe')
            }
          } else{
            history.push('/memberships')
          }
        } else{
          history.push('/authentication/sign-in')
        }
      } catch (error) {
        setLoading(false)
        history.push('/authentication/sign-in')
        console.error('Error during token validation:', error);
      }
      setLoading(false)
    };

    const getUserToken = () => {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        for (const cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (name === 'userToken') {
                return value;
            }
        }
        return null;
  };

  const handleRemoveNotification = (index, id) => {
    const updatedOptions = [...notifications];
    updatedOptions.splice(index, 1);
    setNotifications(updatedOptions);
    removeNotification(getUserToken(), id)
  };

  const timePassed = (unixTime) => {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeDifference = currentTime - unixTime;
  
      if (timeDifference < 60) {
        return `${Math.floor(timeDifference)} seconds ago`;
      } else if (timeDifference < 3600) {
        return `${Math.floor(timeDifference / 60)} minutes ago`;
      } else if (timeDifference < 86400) {
        return `${Math.floor(timeDifference / 3600)} hours ago`;
      } else if (timeDifference < 604800) {
        return `${Math.floor(timeDifference / 86400)} days ago`;
      } else if (timeDifference < 2592000) {
        return `${Math.floor(timeDifference / 604800)} weeks ago`;
      } else if (timeDifference < 31536000) {
        return `${Math.floor(timeDifference / 2592000)} months ago`;
      } else {
        return `${Math.floor(timeDifference / 31536000)} years ago`;
      }
  };

  useEffect(() => {

    setLoading(true)
    const token = getUserToken();
    if (token) {
      validateToken(token);
      getInfo(token);
    } else{
      history.push('/authentication/sign-in')
    }
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();
    const delay = 5000; // 5000 milliseconds = 5 seconds

    const intervalId = setInterval(() => {
      getInfo(token)
      console.log('Function executed every 5 seconds');
    }, delay);
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("scroll", handleTransparentNavbar);
      clearInterval(intervalId);
    };
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
    {notifications.length > 0 ? (
      <div>
      {notifications.map((option, index) => (
        <NotificationItem
          color="text"
          image={
            <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
              notifications
            </Icon>
          }
          title={["", option.message]}
          date={timePassed(Number(option.date))}
          onClick={() => handleRemoveNotification(index, option.id)}
        />
      ))}
      </div>
    ):(
      <p style={{color: 'white', padding: '10px'}}>No Notifications</p>
    )}
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </VuiBox>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox color={light ? "white" : "inherit"}>
              {logo ? (
                <IconButton sx={navbarIconButton} size="small">
                  <p style={{color: 'white'}}>{name}</p>
                  <img src={`data:image/jpg;charset=utf-8;base64,${logo}`} alt="Logo" style={{width:'40px', margin: '10px', borderRadius: '10px'}} />
                </IconButton>
              ) : (
                <div>
                  {load ? (
                    <p style={{color: 'white'}}>Loading...</p>
                  ) : (
                    <Link to="/authentication/sign-in">
                  <IconButton sx={navbarIconButton} size="small">
                    <Icon
                      sx={({ palette: { dark, white } }) => ({
                        color: light ? white.main : dark.main,
                      })}
                    >
                      account_circle
                    </Icon>
                    <VuiTypography
                      variant="button"
                      fontWeight="medium"
                      color={light ? "white" : "dark"}
                    >
                      Sign in
                    </VuiTypography>
                  </IconButton>
                </Link>
                  )}
                </div>
              )}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={"text-white"}>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                {notifications.length > 0 && (
                  <p style={{color: 'white', fontSize: 8, marginBottom: '10px', backgroundColor: 'red', borderRadius: '10px', padding: '3px'}}>{notifications.length}</p>
                )}
              </IconButton>
              {renderMenu()}
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
