import React, { useState, useEffect } from 'react';
import { Elements, CardElement, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Initialize stripe outside of the component to avoid re-creating the stripe object on every render.

import PageLayout from "examples/LayoutContainers/PageLayout";
import Card from "@mui/material/Card";
import ForgedFitnessLogo from 'assets/images/forgedfitnessLogo.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Description } from '@mui/icons-material';
const stripePromise = loadStripe('pk_test_51OVFYTI07w8k740x3lAwqnyOaNOoBPzMuGfDaLefUjEdGHUGPeJdtk2oa7m1OmHIXsbR9LZnAzGn6Z7IjCSJB0nW00rOPuCq7E')


const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#fff",
      fontSize: "16px",
      backgroundColor: "#333", // Adjusted to a darker tone for elegance
      "::placeholder": {
        color: "#aab7c4",
      },
      padding: '10px 12px',
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = ({ closeModal }) => {

  const getUserToken = () => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'userToken') {
            return value;
        }
    }
    return null; // Return null if userToken cookie is not found
  };


  const stripe = useStripe();
  const navigate = useHistory().push;
  const elements = useElements();
  const token = getUserToken();
  const [clientSecret, setClientSecret] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const membership = JSON.parse(localStorage.getItem('membership') || '{}');

  useEffect(() => {
    // Fetch the client secret and other necessary data from the backend

    const fetchClientSecret = async () => {
      const response = await fetch('https://www.phynux.tech/create-setup-intent', {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ gym_id: '!PHYNUX!' }),
      });

      const data = await response.json();
      if (response.ok) {
        setClientSecret(data.clientSecret);
      } else {
        console.error('Failed to fetch client secret:', data);
      }
    };

    fetchClientSecret();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
        console.error('All fields must be filled out');
        alert('missing information')
        return;
    }

    setIsSubmitting(true)

    const cardElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const postalCode = document.getElementById('postalCode').value; // Assuming you give your input an ID
    const Name = document.getElementById('Name').value;

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: Name, // Example name, adjust accordingly
          address: {
            postal_code: postalCode, // Include the postal code in billing details
          },
        },
      },
    });

    if (result.error) {
      console.error(result.error.message);
      alert('Subscription Error: ' + result.error.message);
    } else {
      // The card has been verified successfully...
      console.log('PaymentMethod:', result.setupIntent.payment_method);
      await createSubscription(result.setupIntent.payment_method);
    }
  };

  const createSubscription = async (paymentMethodId) => {
    const response = await fetch('https://www.phynux.tech/subscribe', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        gym_id: '!PHYNUX!',
        membership_cost: Math.round(Number(membership.cost) * 100),
        membership_name: membership.membership_name, // Ensure this matches your backend expectation
        type: membership.membership_type, // Ensure this matches your backend expectation
        membership_id: membership.membership_id,
        description: membership.description,
        paymentMethodId, // Sending the PaymentMethod ID to the backend
      }),
    });

    if (response.ok) {
      const data = await response.json();
      alert('Success: Your subscription is confirmed!');
      closeModal(); // Close the modal on successful subscription
      navigate(`/connect_stripe`); // Adjust the route as necessary
    } else {
      const errorData = await response.json();
      alert('Subscription Error: ' + errorData.error);
    }
  };



  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <div style={{textAlign: 'center'}}>
          <img src={ForgedFitnessLogo} alt="Stripe Logo" style={{
              marginBottom: '10px',
              maxWidth: '110px', // Adjust as needed for your layout
              height: 'auto',
              alignSelf: 'center',

            }} />
            <h4 style={{ color: "#fff", fontSize: '30px' }}>Checkout</h4>
        </div>
        <h4 style={{ color: "#fff" }}>{membership.membership_name}</h4>
        <p style={{ color: "#aab7c4" }}>
          Total due now: <strong>${membership.cost}</strong>
        </p>
          {membership.membership_type == 'day' ? (
            <p style={{ color: "#aab7c4" }}>
              'balls'
            </p>
          ):(
            <p style={{ color: "#aab7c4" }}>
              {`This will be billed ${membership.membership_type.toLowerCase()}ly.`}
            </p>
          )}
        <div style={{ marginBottom: "20px" }}>
          <label style={{ marginBottom: "10px", display: "block", color: 'white' }}>
            Card Number
            <CardNumberElement options={ELEMENT_OPTIONS} />
          </label>
          <label style={{ marginBottom: "10px", display: "block", color: 'white' }}>
            Expiration Date
            <CardExpiryElement options={ELEMENT_OPTIONS} />
          </label>
          <label style={{ marginBottom: "10px", display: "block", color: 'white' }}>
            CVC
            <CardCvcElement options={ELEMENT_OPTIONS} />
          </label>
          <label style={{ marginBottom: "10px", display: "block", color: 'white' }}>
            ZIP/Postal Code
            <input
              id="postalCode" // Adding an ID for easier access
              type="text"
              inputMode="numeric" // Suggests to browsers to show a numeric keyboard
              pattern="[0-9]*" // Allows only numbers
              maxLength="5" // Adjust according to your requirements
              required
              placeholder="ZIP Code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              style={{
                fontSize: "16px",
                color: "#fff",
                backgroundColor: "#333",
                border: "1px solid #aab7c4",
                borderRadius: "4px",
                padding: "10px 12px",
                display: "block",
                width: "100%",
                '::placeholder': {
                  color: "#aab7c4"
                }
              }}
            />
          </label>
          <label style={{ marginBottom: "10px", display: "block", color: 'white' }}>
            Card Holder Name
            <input
              id="Name" // Adding an ID for easier access
              type="text"
              required
              placeholder="Name"
              value={cardHolderName}
              onChange={(e) => setCardHolderName(e.target.value)}
              style={{
                fontSize: "16px",
                color: "#fff",
                backgroundColor: "#333",
                border: "1px solid #aab7c4",
                borderRadius: "4px",
                padding: "10px 12px",
                display: "block",
                width: "100%",
                '::placeholder': {
                  color: "#aab7c4"
                }
              }}
            />
          </label>
        </div>
        <button
          type="submit"
          disabled={!clientSecret || isSubmitting} // Button is disabled if no clientSecret or isSubmitting is true
          style={{
            width: "100%",
            padding: "12px",
            border: "none",
            borderRadius: "4px",
            backgroundColor: !clientSecret || isSubmitting ? "#9CA3AF" : "#10B981", // Grey out if disabled
            color: "white",
            fontSize: "16px",
            cursor: !clientSecret || isSubmitting ? "default" : "pointer",
            transition: "background-color 0.2s ease",
            marginBottom: "20px",
          }}
        >
        Checkout <strong>${membership.cost}</strong>
        </button>
      </form>
    </Card>
  );
};

const CheckoutScreen = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // Ensure it takes minimum full viewport height
        backgroundColor: '#121212',
        padding: '20px', // Add padding to prevent content from sticking to the edges
      },
      header: {
        textAlign: 'center',
        color: '#FFFFFF',
        fontSize: '2rem', // Make it responsive
        marginBottom: '2rem',
      },
      membershipName: {
        fontSize: '1.5rem', // Responsive font size
        color: '#FFF',
        fontWeight: '600', // Bold but not too bold
      },
      membershipDetails: {
        backgroundColor: '#333',
        borderRadius: '8px',
        padding: '20px',
        width: '100%', // Use 100% of the container width
        maxWidth: '600px', // Keep it sensible on larger screens
        color: '#CCC', // Lighter text color for readability
        marginBottom: '20px',
      },
      detailText: {
        lineHeight: '1.5', // Improve readability
        textAlign: 'center',
        marginBottom: '10px', // Space out elements
      },
      totalContainer: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: '#FFF',
        textAlign: 'center',
        marginTop: '20px',
      },
      submitButton: {
        display: 'block', // Take full width of the container
        width: '100%', // Ensure button stretches
        maxWidth: '300px', // But not beyond a reasonable size
        margin: '20px auto', // Center button horizontally
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#4CAF50', // A more vibrant color for the button
        color: '#FFF',
        fontSize: '1rem',
        cursor: 'pointer',
        border: 'none', // Remove any default border
        transition: 'background-color 0.3s ease', // Smooth transition for hover effect
        ':hover': {
          backgroundColor: '#45a049', // Slightly darker on hover
        },
      },
    };


  return (
    <Elements stripe={stripePromise}>
      <PageLayout>
          <div style={{alignSelf: 'center', padding: '100px'}}>
            <CheckoutForm closeModal={() => setModalIsOpen(false)} />
          </div>
      </PageLayout>
    </Elements>
  );
};

export default CheckoutScreen;
