
// @mui material components
// @mui icons
import React, { useState, useEffect } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Images
import profile1 from "assets/images/profile-1.png";
import profile2 from "assets/images/profile-2.png";
import profile3 from "assets/images/profile-3.png";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Footer from "examples/Footer";
// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import Welcome from "../profile/components/Welcome/index";
import CarInformations from "./components/CarInformations";


const getUserToken = () => {
  const cookies = document.cookie.split(';').map(cookie => cookie.trim());
  for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'userToken') {
          return value;
      }
  }
  return null; // Return null if userToken cookie is not found
};

function Overview() {

  const [Data, setData] = useState()


  const getGymStats = async (token) => {
    const searchEndpoint = 'https://www.phynux.tech/api/forged-fitness/getGymInfo';

    try {
      const response = await fetch(searchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();

      setData(data)
      console.log(data)
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const token = getUserToken();
    
    if (token) {
      getGymStats(token);
    }
  }, []);


  return (
    <DashboardLayout>
      {Data && (
        <Header name={Data.gym_name} email={Data.email} />
      )}
      <VuiBox mt={5} mb={3}>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid
            item
            xs={12}
            xl={4}
            xxl={3}
            sx={({ breakpoints }) => ({
              minHeight: "400px",
              [breakpoints.only("xl")]: {
                gridArea: "1 / 1 / 2 / 2",
              },
            })}
          >
            <Welcome />
          </Grid>
          {/*<Grid
            item
            xs={12}
            xl={5}
            xxl={6}
            sx={({ breakpoints }) => ({
              [breakpoints.only("xl")]: {
                gridArea: "2 / 1 / 3 / 3",
              },
            })}
          >
            <CarInformations />
          </Grid>*/}
          <Grid
            item
            xs={12}
            xl={3}
            xxl={3}
            sx={({ breakpoints }) => ({
              [breakpoints.only("xl")]: {
                gridArea: "1 / 2 / 2 / 3",
              },
            })}
          >
            {Data && (
              <ProfileInfoCard
                Membership_Name={'https://www.phynux.tech/forgedfitness/gymReview?gymId='+Data.gym_id}
                info={{
                  Membership: Data.membership_name,
                  Price: `$${Data.cost}`,
                  Billed: `${Data.membership_type}ly`,
                  Account: 'To initiate any actions that would modify your account, please contact us at (513) 415-2483.'
                }}
                social={[
                  
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} xl={3} height="100%">
            {/*<PlatformSettings />*/}
          </Grid>
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Overview;
