import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ForgedFitnessLogo from 'assets/images/forgedfitnessLogo.png'

import PageLayout from "examples/LayoutContainers/PageLayout";
import Card from "@mui/material/Card";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import BillingInformation from "layouts/webpages/components/membership_list";

const MembershipOptions = () => {
  const [memberships, setMemberships] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const navigate = useHistory();

  const getUserToken = () => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'userToken') {
            return value;
        }
    }
    return null; // Return null if userToken cookie is not found
  };

  const token = getUserToken();

  useEffect(() => {
    getGymMembershipOptions();
  }, []);

  const getGymMembershipOptions = () => {
    fetch('https://www.phynux.tech/api/forged-fitness/getGymMembershipOptions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ gym_id: '!PHYNUX!' })
    })
      .then(response => response.json())
      .then(data => {
        if (!data.status) {
          navigate.push('/gym_search');
        } else {
          setMemberships(data.memberships);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handlePromoCodeChange = event => {
    setPromoCode(event.target.value);
  };

  const applyPromoCode = () => {
    fetch('https://www.phynux.tech/api/forged-fitness/checkPromoCodes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ gym_id: '!PHYNUX!', promoCode })
    })
      .then(response => response.json())
      .then(data => {
        if (!data.status) {
          alert('Membership Not Found');
        } else {
          setMemberships(data.memberships);
          setSelectedMembership(data.memberships[0]);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
    });
  };

  const signupButton = () => {
    localStorage.setItem('membership', JSON.stringify(selectedMembership));
    navigate.push('/checkout_stripe');
  };

  return (
    <PageLayout>
      <div style={styles.container}>
      <Card style={{ alignItems: 'center', width: '80%'}}>
      <img src={ForgedFitnessLogo} alt="Forged Fitness Logo" style={styles.logo} />
      <div style={styles.membershipOptionsContainer}>
        <BillingInformation memberships={memberships} setSelectedMembership={(membership) => {setSelectedMembership(membership)}} selectedMembership={selectedMembership} />
        <div style={styles.promoContainer}>
          <VuiInput type="search" placeholder="Enter Membership Code"  onChange={handlePromoCodeChange} fontWeight="500" style={{margin: "5px"}} />
          <Card  backgroundColor={'white'}>
          <VuiButton variant="text" color="text" onClick={applyPromoCode}>
            Apply
          </VuiButton>
          </Card>
          {/*<button onClick={applyPromoCode} style={styles.applyPromoButton}>Apply</button>*/}
        </div>
        <div style={{textAlign: 'center'}}>
        <VuiButton variant="text" color='white' onClick={signupButton} disabled={!selectedMembership}>
          <h2 style={{backgroundColor: '#8A9A5B', padding: '10px', borderRadius: '10px'}}>Sign Up!</h2>
        </VuiButton>
        </div>
      </div>
      </Card>
      </div>
    </PageLayout>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    padding: '2rem',
    color: '#FFFFFF',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '130px',
    height: 'auto',
    marginBottom: '2rem',
  },
  membershipOptionsContainer: {
    maxWidth: '600px',
    width: '100%',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    textAlign: 'center',
    color: 'white'
  },
  membershipOption: {
    marginBottom: '1.5rem',
    backgroundColor: '#1e1e1e',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    padding: '1.5rem',
  },
  selectedMembership: {
    backgroundColor: '#355E3B',
  },
  membershipName: {
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  membershipDescription: {
    color: '#ccc',
    fontSize: '1rem',
    marginBottom: '0.5rem',
  },
  membershipDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#aaa',
    fontSize: '1rem',
  },
  promoContainer: {
    display: 'flex',
    marginBottom: '1rem',
  },
  promoInput: {
    flex: 1,
    marginRight: '1rem',
    padding: '0.5rem',
    fontSize: '1rem',
    borderRadius: '0.5rem',
    border: '1px solid #ccc',
  },
  applyPromoButton: {
    backgroundColor: '#355E3B',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: '0.5rem 1rem',
    borderRadius: '0.5rem',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
  },
  signupButton: {
    alignSelf: 'center',
    backgroundColor: '#8A9A5B',
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '1rem 2rem',
    borderRadius: '1.5rem',
    margin: '2rem auto',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  disabledSignupButton: {
    backgroundColor: '#8B8B8B',
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '1rem 2rem',
    borderRadius: '1.5rem',
    margin: '2rem auto',
    border: 'none',
    outline: 'none',
    cursor: 'not-allowed',
    opacity: '0.5',
  },
};

export default MembershipOptions;
