import React from 'react';
import PageLayout from "examples/LayoutContainers/PageLayout";
import Card from "@mui/material/Card";
import StripeLogo from 'assets/images/stripe_logo.png'
import ForgedFitnessLogo from 'assets/images/forgedfitnessLogo.png'
import text from 'assets/theme/components/button/text';
import { Widgets } from '@mui/icons-material';

const StripeConnect = () => {

  const getUserToken = () => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'userToken') {
            return value;
        }
    }
    return null; // Return null if userToken cookie is not found
  };


  const token = getUserToken(); // Assuming token is passed through URL parameters

  const handleStripeConnect = async () => {
    try {
      const response = await fetch('https://www.phynux.tech/create-stripe-url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (data.url) {
        // Redirect user directly to the Stripe URL upon button click
        window.location.href = data.url;
      } else {
        alert('URL not provided');
      }
    } catch (error) {
      console.error('An error occurred during Stripe connect:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <PageLayout>
      <div style={styles.container}>
        <img src={StripeLogo} alt="Stripe Logo" style={styles.logoImage} />
        <Card>
        <img src={ForgedFitnessLogo} alt="Stripe Logo" style={styles.forgedfitnessImage} />
          <p style={{color: 'white', width: '80%', alignSelf: 'center', textAlign: 'center'}}>
            Forged-Fitness partners with Stripe to securely process your payments. Connect with Stripe to get started.
          </p> 
          <button style={styles.button} onClick={handleStripeConnect}>Connect with Stripe</button>
          </Card>
      </div>
    </PageLayout>
  );
};

const styles = {
  safeAreaView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#dbcaca',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  innerContainer: {
    margin: '20px 0',
    padding: '20px',
    backgroundColor: '#333',
    borderRadius: '10px',
    color: '#fff',
    textAlign: 'center',
  },
  text: {
    fontSize: '1rem',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 15px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '20px',
    width: '80%',
    alignSelf: 'center'
  },
  logoImage: {
    marginBottom: '20px',
    maxWidth: '200px', // Adjust as needed for your layout
    height: 'auto',
    borderRadius: '10px'
  },
  forgedfitnessImage: {
    marginBottom: '20px',
    maxWidth: '120px', // Adjust as needed for your layout
    height: 'auto',
    alignSelf: 'center'
  },
  // Additional styles as needed
};

export default StripeConnect;
