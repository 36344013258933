import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import Card from "@mui/material/Card";
// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";

// Billing page components
import PaymentMethod from "layouts/gym/components/PaymentMethod";
import Invoices from "layouts/gym/components/Invoices";
import Item_list from "layouts/store/components/item_list";
import Transactions from "layouts/gym/components/Transactions";
import VuiButton from "components/VuiButton";
import forgedFitnessLogo from "assets/images/forgedfitnessLogo.png";

function Store() {

    const [store_items, setStore_Items] = useState([]);
    const [base64String, setString] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [adding, setAdding] = useState(false);
    const [newProduct, setNewProduct] = useState({
      item_name: '',
      item_description: '',
      item_price: '',
      image_base64: '',
      isSaved: 'FALSE'
    });
  

  const getUserToken = () => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'userToken') {
            return value;
        }
    }
    return null; // Return null if userToken cookie is not found
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setString(base64String);
        setNewProduct({ ...newProduct, image_base64: base64String })
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedOptions = [...store_items];
    updatedOptions.splice(index, 1);
    setStore_Items(updatedOptions);
  };


  useEffect(() => {
    getStoreItems()
  }, []);



  const uploadProducts = async () => {
    const Has_token = getUserToken();
    if (Has_token) {
      setIsSubmitting(true)
      try {
        const searchEndpoint = 'https://www.phynux.tech/api/forged-fitness/product_upload';
        const response = await fetch(searchEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: Has_token, products: store_items }),
        });
        const data = await response.json();
        if(data.status){
          getStoreItems();
          alert('Products Saved Successfully');
          setIsSubmitting(false)
        } else{
          alert('Error Saving Products');
          setIsSubmitting(false)
        }
      } catch (error) {
        setIsSubmitting(false)
        console.error('Error fetching data:', error);
      }
    }
  };


  const getStoreItems = async () => {
    const searchEndpoint = 'https://www.phynux.tech/forgedfitness/api/products';
    const token = getUserToken()
    try {
      const response = await fetch(searchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, isGym: true }),
      });
      const data = await response.json();
      console.log(data)
        setStore_Items(data);
        console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAddProduct = async () => {
    setAdding(true)
    if (newProduct.item_name && newProduct.item_description && newProduct.item_price && newProduct.image_base64) {
        setStore_Items([...store_items, newProduct]);
        setNewProduct({
          item_name: '',
          item_description: '',
          item_price: '',
          image_base64: '',
          isSaved: 'FALSE'
        })
        setString(null)
        setAdding(false)
    }
     else {
      alert('Please fill out all fields before adding a product.');
    }
  };



  return (
    <DashboardLayout>
      <DashboardNavbar/>
        <Item_list store_items={store_items} removeProduct={handleRemoveProduct} />
        <Card style={{margin: '10px'}}>
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Add Product
          </VuiTypography>
          {adding && newProduct.image_base64.length<=0 && <p style={{color: 'red', marginLeft: '10px', fontSize: 10, alignSelf: 'center'}}>Please upload product image</p>}
          <Card style={{alignSelf: 'center', borderColor: 'red', borderWidth: adding && newProduct.image_base64.length<=0 ? '1px':'0px'}}>
            <h3 style={{color: 'white', marginBottom: '10px'}}>Product Image</h3>
              {base64String ? (
                <img src={base64String} alt="Product Image" style={{
                  width: '80px',
                  height: 'auto',
                  borderRadius: '5px',
                  alignSelf: 'center'
                }}/>
                ) : (
                  <div style={{color: 'white', margin: '10px', alignSelf: 'center'}}>Upload Product Image</div>
                )}
                <input type="file" onChange={handleFileChange} accept="image/*" style={{
                  color: 'white',
                  margin: '10px',
                  alignSelf: 'center'
                }} />
          </Card>
          <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Product Name</p>
          {adding && newProduct.item_name<=0 && <p style={{color: 'red', marginLeft: '10px', fontSize: 10}}>Please enter product name</p>}
          <VuiInput type="text" placeholder="Enter Name" value={newProduct.item_name} success={newProduct.item_name.length>0} error={adding && newProduct.item_name<=0}  onChange={(e) => setNewProduct({ ...newProduct, item_name: e.target.value })} fontWeight="500" style={{margin: "5px"}} />
          <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Product Description</p>
          {adding && newProduct.item_description<=0 && <p style={{color: 'red', marginLeft: '10px', fontSize: 10}}>Please enter product description</p>}
          <VuiInput type="text" placeholder="Enter Description" value={newProduct.item_description} success={newProduct.item_description.length>0} error={adding && newProduct.item_description<=0}  onChange={(e) => setNewProduct({ ...newProduct, item_description: e.target.value })} fontWeight="500" style={{margin: "5px"}} />
          <p style={{color: 'white', marginLeft: '5px', fontSize: 15}}>Product Price <strong style={{color: '#3e9c35'}}>(${newProduct.item_price/100})</strong></p>
          {adding && newProduct.item_price<=0 && <p style={{color: 'red', marginLeft: '10px', fontSize: 10}}>Please enter product price</p>}
          <VuiInput type="text" placeholder="0.00" value={newProduct.item_price} success={newProduct.item_price.length>0} error={adding && newProduct.item_price<=0}  onChange={(e) => {
            const input = e.target.value;
            if (/^\d+$/.test(input) || input === "") {
              setNewProduct({ ...newProduct, item_price: e.target.value })}
            }
            } fontWeight="500" style={{margin: "5px"}} />
          <VuiButton variant='text' disabled={isSubmitting} onClick={handleAddProduct}>{<p style={styles.addButton}>Add Product</p>}</VuiButton>
        </Card>
        <Card>
        <VuiButton variant='text' disabled={isSubmitting} onClick={uploadProducts}>{isSubmitting ? <p style={styles.submitButton}>Submitting...</p> : <p style={styles.submitButton}>Submit</p>  }</VuiButton>
        </Card>

    </DashboardLayout>
  );
}

const styles = {
  submitButton: {
    width: '100%',
    backgroundColor: '#008CBA',
    color: 'white',
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
  },
  addButton: {
    width: '100%',
    backgroundColor: 'blue',
    color: 'white',
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
  },
}

export default Store;
