
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";

function Bill({ name, description, price, type, removeMembership, editMembership, isSaved, image, noGutter }) {
  const { gradients } = colors;
  const { bill } = gradients;

  const isBase64Png = (str) => {
    return str.startsWith('data:image/');
  };

  let imageUrl;
  if (isBase64Png(image)) {
    imageUrl = image;
  } else {
    // Determine image format for non-PNG images
    const format = image.startsWith('data:image/jpeg') ? 'jpeg' :
                   image.startsWith('data:image/png') ? 'png' :
                   image.startsWith('data:image/gif') ? 'gif' :
                   image.startsWith('data:image/svg+xml') ? 'svg+xml' :
                   'unknown';
    // Use the appropriate MIME type for the image format
    imageUrl = `data:image/${format};base64,${image}`;
  }

  return (
    <VuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ background: linearGradient(bill.main, bill.state, bill.deg) }}
      borderRadius="lg"
      p="24px"
      mb={noGutter ? "0px" : "24px"}
      mt="20px"
    >
      <VuiBox width="100%" display="flex" flexDirection="column">
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb="5px"
        >
          <VuiBox
          display="flex"
          alignItems="center"
          mt={{ xs: 2, sm: 0 }}
          ml={{ xs: -1.5, sm: 0 }}
          sx={({ breakpoints }) => ({
            [breakpoints.only("sm")]: {
              flexDirection: "column",
            },
          })}
          >
            <img src={imageUrl} alt="Gym Logo" style={{
              width: '80px',
              height: 'auto',
              borderRadius: '5px',
              alignSelf: 'center'
            }}/>
          </VuiBox>
          <VuiTypography
            variant="button"
            color="white"
            fontWeight="medium"
            textTransform="capitalize"
            style={{fontSize: 20}}
          >
            {name}
          </VuiTypography>

          <VuiBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
            sx={({ breakpoints }) => ({
              [breakpoints.only("sm")]: {
                flexDirection: "column",
              },
            })}
          >
            <VuiBox mr={1}>
              <VuiButton variant="text" color="error" onClick={removeMembership}>
                <Icon sx={{ mr: "4px" }}>delete</Icon>&nbsp;DELETE
              </VuiButton>
            </VuiBox>
            <VuiButton variant="text" color="text" onClick={editMembership}>
              <Icon sx={{ mr: "4px" }}>edit</Icon>&nbsp;EDIT
            </VuiButton>
          </VuiBox>
        </VuiBox>
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            Description:&nbsp;&nbsp;&nbsp;
            <VuiTypography
              variant="caption"
              color="text"
              fontWeight="regular"
              textTransform="capitalize"
            >
              {description}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            Price (tax included):&nbsp;&nbsp;&nbsp;
            <VuiTypography variant="caption" fontWeight="regular" color="text">
              ${price}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        {isSaved == 'FALSE' && <p>NOT SAVED</p>}
      </VuiBox>
    </VuiBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Bill;
